document.documentElement.classList.remove('no-js');

import Carousel from './components/carousel'
import AOS from 'aos';
import SmoothScroll from 'smooth-scroll';
import Swiper, { Navigation, Pagination } from 'swiper';
// import 'swiper/css'
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
var scroll = new SmoothScroll('a[href*="#"]', {
  // Function. Custom easing pattern
  // If this is set to anything other than null, will override the easing option above
  customEasing: function (time) {

    // return <your formulate with time as a multiplier>

    // Example: easeInOut Quad
    return time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time;

  }
});
Swiper.use([Navigation, Pagination]);
var swiper = new Swiper(".mySwiper", {
  slidesPerView: 1,
  spaceBetween: 20,

  loop: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    type: 'bullets',
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
var btnSubMenuMobile = document.getElementById("id-sub-menu__btn");
var btnMenuMobile = document.getElementById("id-btn-mobile");
btnMenuMobile.addEventListener('click', function (e) {
  var btn = e.target;
  var menu = document.getElementById("id-desktop-menu");

  if (menu.className === "desktop-menu") {
    menu.className += " desktop-menu-open";
    btn.className = "menu-open";

  } else {
    menu.className = "desktop-menu";
    btn.className = "";
  }
});

btnSubMenuMobile.addEventListener('click', function (e) {
  var btn = e.target;
  var menu = document.getElementById("id-sub-menu-content");

  if (menu.className === "sub-menu-content") {
    menu.className += " sub-menu-content--open";
    btn.className += " sub-menu-open";

  } else {
    menu.className = "sub-menu-content";
    btn.className = "desktop-menu__item sub-menu__btn";
  }
});


var modal = document.getElementById("modal-video");
var btn = document.getElementById("btn-video");
var span = document.getElementById("close-modal");

if (btn != null) btn.onclick = function () {
  modal.style.display = "flex";
}

if (span != null) span.onclick = function () {
  modal.style.display = "none";
  let iframe = document.querySelector('iframe');
  let src = iframe.getAttribute("src");
  iframe.setAttribute("src", src);
}

if (modal != null) window.onclick = function (event) {
  if (event.target == modal) {
    modal.style.display = "none";
  }
}

var inputs = document.querySelectorAll('.c-form__input--file');

for (let index = 0; index < inputs.length; index++) {
  const input = inputs[index];
  var labels = input.labels;
  var labelVal = labels[0].innerHTML;

  input.addEventListener('change', function (e) {
    var fileName = '';
    if (this.files && this.files.length > 1)
      fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
    else
      fileName = e.target.value.split('\\').pop();

    if (fileName)
      input.labels[0].innerHTML = fileName;
    else
      input.labels[0].innerHTML = labelVal;
  });

  // Firefox bug fix
  input.addEventListener('focus', function () { input.classList.add('has-focus'); });
  input.addEventListener('blur', function () { input.classList.remove('has-focus'); });
}
// Array.prototype.forEach.call(inputs, function (input) {
//   var inputs = document.querySelectorAll('.c-form__input--file');
//   console.log(inputs);
//   var label = input.label;
//   var labelVal = label[0].innerHTML;

//   input.addEventListener('change', function (e) {
//     var fileName = '';
//     if (this.files && this.files.length > 1)
//       fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
//     else
//       fileName = e.target.value.split('\\').pop();

//     if (fileName)
//       label.querySelector('span').innerHTML = fileName;
//     else
//       label[0].innerHTML = labelVal;
//   });

//   // Firefox bug fix
//   input.addEventListener('focus', function () { input.classList.add('has-focus'); });
//   input.addEventListener('blur', function () { input.classList.remove('has-focus'); });
// });

if (document.body.contains(document.getElementById("btnSpontaneousCandidacy"))) {
  var btnSpontaneousCandidacy = document.getElementById("btnSpontaneousCandidacy");
  btnSpontaneousCandidacy.addEventListener('click', function (e) {
    var element = document.getElementById("job-form").scrollIntoView();
  });
}



AOS.init({
  once: true,
});